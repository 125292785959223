@import './styles/index.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html,
body {
  height: 100% !important;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0px !important;
  background-color: #eff2f6;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0.5em;

  &.bg {
    font-size: 16px;
    line-height: 24px;
  }

  &.bold {
    font-weight: 500;
  }
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 38px;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 38px;
}

h3 {
  font-weight: 500;
  font-size: 16px;
}

.p-med {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.title-component {
  color: #1071c1;
}

.padding-component {
  padding: 60px;
  background-color: #eff2f6;
  min-height: 100vh;
}

.create-button {
  background-color: #1d65a0;
  border-radius: 6px;
  padding: 8px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white !important;
  border: none;
  font-family: Poppins;
}

.close-button {
  background-color: #eff2f6;
  border-radius: 6px;
  padding: 8px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #1d65a0 !important;
  border: none;
  font-family: Poppins;
}

.disabled-button {
  background-color: grayscale($color: #969494);
  border-radius: 6px;
  padding: 8px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white !important;
  border: none;
  font-family: Poppins;
}

.button-only {
  display: flex;
  justify-content: flex-end;
}

.mat-step-header .mat-step-icon {
  left: 40%;
}

mat-radio-button {
  label {
    margin-bottom: 0px;
    font-family: Poppins;
  }
}

input {
  font-family: Poppins !important;
}

textarea {
  font-family: Poppins !important;
}

mat-select {
  font-family: Poppins !important;
}

mat-option {
  font-family: Poppins !important;
}

mat-paginator {
  .mat-mdc-text-field-wrapper {
    height: 30px;
    align-items: center;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.fulfill-tag {
  border-radius: 23px;
  background: #fff6f6;
  color: #5cb84d;
  border: 1px solid #5cb84d;
  padding: 5px 7px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.not-apply-tag {
  border-radius: 23px;
  background: #fff6f6;
  color: #f22518;
  border: 1px solid #f22518;
  padding: 5px 7px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.not-applicable-tag {
  border-radius: 23px;
  background: #fff6f6;
  color: #1d65a0;
  border: 1px solid #1d65a0;
  padding: 5px 7px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.close-button {
  color: #1d65a0;
  border-radius: 6px;
  padding: 8px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  border: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.mat-tab-label {
  font-weight: 500;
  font-size: 22px;
  line-height: 38px;
}

.mat-calendar-body-selected {
  color: black;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked.mat-primary,
.mat-pseudo-checkbox-indeterminate.mat-primary {
  background: unset;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #1071c1;
  --mdc-snackbar-supporting-text-color: black;

  &.custom-snackbar {
    --mdc-snackbar-container-color: white;
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled='false'] {
  background-color: white;
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .form-container {
    width: 95%;
    margin: auto;
    padding-bottom: 24px !important;
    display: block;
    background: rgb(253, 253, 253);
  }

  .btn {
    font-size: 1em;
  }
}

@media (max-width: 1024px) {
  .padding-component {
    padding: 20px;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .form-container {
    width: 95%;
    margin: auto;
    padding-bottom: 24px !important;
    display: block;
    background: rgb(253, 253, 253);
  }

  .btn {
    font-size: 1em;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .form-container {
    width: 95%;
    margin: auto;
    padding-bottom: 24px !important;
    display: block;
    background: rgb(253, 253, 253);
  }

  .btn {
    font-size: 1em;
  }
}

/*
  MatSnackbar
*/

.mdc-snackbar.mat-mdc-snack-bar-container.mdc-snackbar--open.success
  .mdc-snackbar__surface,
.mdc-snackbar.mat-mdc-snack-bar-container.mdc-snackbar--open.info
  .mdc-snackbar__surface,
.mdc-snackbar.mat-mdc-snack-bar-container.mdc-snackbar--open.warn
  .mdc-snackbar__surface,
.mdc-snackbar.mat-mdc-snack-bar-container.mdc-snackbar--open.error
  .mdc-snackbar__surface {
  background: white;
}

.modal-styles {
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
