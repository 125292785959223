@use 'sass:map';
@use '@angular/material' as mat;

@mixin modal($theme) {
  // .mat-mdc-dialog-container {
  //   overflow: hidden;
  //   height: 100% !important;
  // }
  .mat-horizontal-content-container {
    padding: 0 0.5em 0.5em 0.5em !important;
  }
}

@mixin theme($theme) {
  @include modal($theme);
}
