@mixin flex($theme) {
  .flex {
    display: flex;
    gap: .5em;
  }

  .flex > * {
    flex: 1;
  }
  
  @media (max-width: 1000px) {
    .flex {
      flex-direction: column;
    }
  }
}

@mixin theme($theme) {
  @include flex($theme);
}
