@use '@angular/material' as mat;

@use './flex.material.scss' as flex;
@use './button.material.scss' as button;
@use './modal.material.scss' as modal;

@include mat.core();

html,
body {
  height: 100%;
  @include mat.all-component-densities(-3);
  input {
    font-size: 14px !important;
  }
}

$audit-blue-palette: (
  100: #4da1df,
  500: #1d65a0,
  700: #0f65a9,
  contrast: (
    500: white,
  ),
);

$audit-accent-palette: (
  100: white,
  500: white,
  700: white,
  contrast: (
    100: #4da1df,
    500: #1d65a0,
    700: #0f65a9,
  ),
);

$primary-palette: mat.define-palette($audit-blue-palette);
$accent-palette: mat.define-palette($audit-accent-palette);
$warn-palette: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
  )
);

@include mat.all-component-themes($theme);
@include flex.theme($theme);
@include button.theme($theme);
@include modal.theme($theme);
