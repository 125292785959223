@use 'sass:map';
@use '@angular/material' as mat;

@mixin button($theme) {
  button.mat-mdc-raised-button {
    height: unset !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    line-height: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
    -webkit-font-smoothing: unset !important;
  }
}

@mixin theme($theme) {
  @include button($theme);
}
